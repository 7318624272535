import React from 'react';
import { io } from "socket.io-client";
import Cookies from 'js-cookie';

import {musicContext, defaultMusic} from "../context/musicContext";


import Retourbutton from "../elements/Retourbutton";

import {Form} from "./form.js"
import {Salon} from "./salon";
import { setUnits } from './unitselectorcontext.js';

import './game.scss'

export var local = {};
export var socket = null;

export class Gameapp extends React.Component{
    static contextType = musicContext;

    constructor(props){
        super(props);
    
        this.state = {
            inQueue : false,
            connected : false,
            err : null
        }

        this.connect = this.connect.bind(this);
    }

    componentDidMount() {
        this.context.setMusicsrc("/musiques/The_Road_Home-The_Vikings-Alexander_Nekarada.mp3");
        this.connect();
    }

    componentWillUnmount(){
        this.context.setMusicsrc(defaultMusic);
        if (socket != null){
            socket.close();
        }
    }

    connect(){
        if (Cookies.get("uid")){
            socket = io();
            socket.onAny((eventName, args) => {
                console.log(eventName, args);
            })

            socket.on("unitList", (arg) => {
                setUnits(arg);
            })

            socket.on("connect", () => {
                this.setState({
                    connected : false,
                    inQueue : true
                })
            })

            socket.on("gameStart", () => {
                this.setState({
                    connected : true,
                    inQueue : false
                })
            })

            socket.on("inQueue", () => {
                this.setState({
                    connected : false,
                    inQueue : true
                })
            })

            socket.on("self-info", (arg) => {
                local.name = arg.name;
                local.team = arg.team;
            })

            socket.on("disconnect", () => {
                this.setState({
                    connected : false
                })
                socket.close();
            })

            socket.on("connect_error", (arg) => {
                this.setState({
                    connected : false,
                    err : arg
                });
                socket.close();
            })
        }
    }



    render(){
        let game;
        if (this.state.connected) {
            game = <Salon/>;
        } else {
            if (this.state.inQueue){
                game = [<p key={1}>En file d'attente</p>];
            } else if (this.state.err){
                game = [<p key={1}>Erreur de connexion : {this.state.err.message}</p>];
            } else {
                game = [<p key={1}>Connexion...</p>];
            }
            game.push(<Retourbutton />);
        }
        return <div className='gameapp rpgui-content rpgui-cursor-default'>
            {game}
        </div>
    }
}
