import React from "react";
import {connexionContext} from "../context/context"
import Navbar from "../elements/Navbar";
import Retourmenubutton from "../elements/Retourmenubutton";
import {Navigate} from "react-router-dom";

import background from "../images/The_public_viewing_davids_coronation_at_the_louvre.jpg";
import logoimg from "../images/logo.jpg";

import "./Connexionpage.css";

export default class Connexionpage extends React.Component {

  static contextType = connexionContext;

  constructor(props){
    super(props);
    this.state = {
      id: "", 
      mdp: "",
      redirect : false,
      error : null
    };
  }

  handleChange(event){
    event.preventDefault();
    switch (event.currentTarget.id) {
      case "id":
        this.setState({id: event.currentTarget.value});
        break;
      case "pswd" :
        this.setState({mdp: event.currentTarget.value});
        break;
      default:
        break;
    }
  };

  handleSubmit(event){
    if(this.state.id === "" || this.state.pswd === ""){
      console.log("Les champs sont vides");
    } else {
      fetch("/api/login/log",{method : "PUT",headers : { "Content-Type" : "application/json"}, body : JSON.stringify({login : this.state.id, pswd : this.state.mdp})}).then((res) => {
        console.log(res.status)
        if(res.status === 200){
          this.context.setLogin(this.state.id);
          this.setState({
            redirect : true
          })
        } else if (res.status === 403) {
          this.setState({
            error : "Identifiant ou mot de passe incorrect"
          });
        } else {
          this.setState({
            error : "Problème de connexion"
          });
        }
      });
    }
  }

  render(){
    return (
        <div>
          { this.state.redirect ? <Navigate to={"/"}/> :
              [
                <Navbar key={1} titre={"Art Of War - Connexion au compte"} logoimg={logoimg} />,
                <div  key={2} id="BoiteConnexion" style={{ backgroundImage:`url(${background})` }}>
                  <input id="id" type="text" placeholder="Identifiant" value={this.state.id} onChange={this.handleChange.bind(this)}/>
                  <input id="pswd" type="password" placeholder="Mot de passe" value={this.state.mdp} onChange={this.handleChange.bind(this)} />
                  <p>{this.state.error}</p>
                  <a href="/creation-compte">Créer un compte</a>
                  <button id="Connecter" onClick={this.handleSubmit.bind(this)}>Se connecter</button>
                  <Retourmenubutton />
                </div>
              ]
          }
        </div>
    )
  }
}
