import React from 'react';
import {socket} from './gameapp.js';

export class ErrMessage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            err : null,
            timeout : null
        }

        this.clear = this.clear.bind(this);
    }

    componentDidMount(){
        socket.on("error", (arg) => {
            if (this.state.timeout !== null){
                clearTimeout(this.state.timeout);
            }
            this.setState({
                err : arg,
                timeout : setTimeout(this.clear, 1000)
            });
        })
    }

    

    clear(){
        clearTimeout(this.state.timeout);
        this.setState({
            err : null,
            timeout : null
        });
    }

    render(){
        return (this.state.err ? <div className='err-message'>
            <p>{this.state.err}</p>
        </div> : "")
    }
}