import React from "react";
import Profilnavbar from "../elements/Profilnavbar";
import Retourmenubutton from "../elements/Retourmenubutton";

import {BoutonMenu} from "../elements/button";

import background from "../images/the_capture_of_carthage.jpg";
import "./Jouerpage.scss";
const Jouerpage = () => (
	<div>
		<Profilnavbar />
		<div id="BoiteJButton" style={{ backgroundImage:`url(${background})` }}>
			<div id="BoiteJButtonGauche" className={"side"}>
				<BoutonMenu disabled>Tuto</BoutonMenu>
				<BoutonMenu disabled>Local</BoutonMenu>
				<BoutonMenu disabled>Campagne</BoutonMenu>
			</div>
			<div id="BoiteJButtonDroite" className={"side"}>
				<BoutonMenu to={"/jouer/game"}>Partie normale</BoutonMenu>
				<BoutonMenu disabled>Partie classé</BoutonMenu>
				<BoutonMenu disabled>Partie personnalisé</BoutonMenu>
			</div>
		</div>
		<Retourmenubutton />
	</div>
);

export default Jouerpage;