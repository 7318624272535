import React from "react";
import Navbar from "../elements/Navbar";
import {BoutonMenu} from "../elements/button";

import background from "../images/The_battle_of_Vercellae.jpg";
import logoimg from "../images/logo.jpg";

import actu_patch_011 from "../images/actu/patch_0.1.1.jpg";
import actu_patch_021 from "../images/actu/patch_0.2.1.jpg";
import actu_patch_031 from "../images/actu/patch_0.3.1.jpg";
import actu_patch_032 from "../images/actu/patch_0.3.2.jpg";

import "./Menupage.css";


const Menupage = () => (
  <div>
    <Navbar titre={"Art Of War"} logoimg={logoimg} />
    <div id="BoiteMenu" style={{ backgroundImage:`url(${background})` }}>
      <div id="Part1" className="Part1 side">
        <BoutonMenu to={"/connexion-compte"}>Se connecter</BoutonMenu>
        <BoutonMenu to={"/creation-compte"}>Créer un compte</BoutonMenu>
        <BoutonMenu to={"/encyclopedie"}>Encyclopédie</BoutonMenu>
        <BoutonMenu to={"/patch"}>Patchnotes</BoutonMenu>
      </div>
      <div id="Part2" className="Part2 side">
        <h2>Actualités :</h2>
        <div id="ScrollBox">
          <p>
            31/10 : Sortie du patch 0.3.2 : Correction de bugs et ajouts mineurs en plus d'un ajustement du général.
            <img className="Image" src={actu_patch_032} alt="patch 0.3.2"></img>
          </p>
          <p>
            27/10 : Sortie du patch 0.3.1 : Ajout des musiques dans le jeu que nous vous laisserons découvrir, de plus
            les visuels durant une partie ont été améliorés.
            <br/>
            <img className="Image" src={actu_patch_031} alt="patch 0.3.1"></img>
          </p>
          <p>
            <p>
              18/10 : Sortie du patch 0.2.1 : Ajout de deux nouvelles maps en test en vue stratégique, si les tests sont concluant, les vues 
              réalistes arriveront bientôt.
            </p>
            <p>
              Rivière à gauche et Lac à droite:
            </p>
            <img className="Image" src={actu_patch_021} alt="patch 0.2.1"></img>
          </p>
          <p>
          14/09 : Sortie du patch 0.1.1 : Équilibrage
          <img className="Image" src={actu_patch_011} alt="patch 0.1.1"></img>

          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Menupage;
