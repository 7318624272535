import React from 'react';
import {Units, UnitSelectionedContext} from './unitselectorcontext.js';

import coin from "../images/coin.jpg";

export class UnitSelector extends React.Component {
    render(){
        let tab = Units.map((elt, index) => {
            let classe = (index === this.context.unit) ? "selected" : "";
            return <div onClick={(e) => {this.context.changeUnit(index)}} className={'unit ' + classe} key={index} id={index}>
                <h4>{elt.name}</h4>
                <p>{elt.prix}<img className="piece" src={coin} alt="pièce"></img></p>
            </div>
        })
        return <div className='unit-selector'>
                {tab}
            </div>;
    }
}

UnitSelector.contextType = UnitSelectionedContext;