import React from "react";

import logoimg from "../images/logo.jpg";
import "./Profilnavbar.css";
import {connexionContext} from "../context/context";

class Profilnavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pseudo : "",
            nbvictoire : "",
            nbdefaite : "",
            winrate : "",
            winstreak : "", 
            elo : "",
            elospike : ""
        }
    }

    componentDidMount() {
        fetch("/api/user/infos").then(res => {
            if (res.status === 200){
                return res.json();
            } else {
                throw new Error(res.text());
            }
        }).then(json => {
            this.setState({
                pseudo : json.id,
                nbvictoire : json.nbwin,
                nbdefaite : json.nbgame-json.nbwin,
                winrate : this.calculWinrate(json.nbgame, json.nbwin),
                winstreak : json.winstreak, 
                elo : json.elo,
                elospike : json.elospike
            })
        }).catch(err => {
            console.log(err);
        })
    }

    calculWinrate(nbgame, nbwin){
        if (nbgame === 0){
            return 0;
        }
        else {
            return Math.round(nbwin/nbgame*100)
        }
    }


    render(){
        return(
            <connexionContext.Consumer>
            {
                value =>
                    <header className="Header">
                        <img className="Logo" src={logoimg} alt="logo"></img>
                        <p>{this.state.pseudo}</p>
                        <p>Victoires : {this.state.nbvictoire}</p>
                        <p>Défaites : {this.state.nbdefaite}</p>
                        <p>Winrate : {this.state.winrate}%</p>
                        <p>Winstreak : {this.state.winstreak}</p>
                        <button id="Deconnexion" onClick={() => {
                            console.log("click");
                            value.disconnect();
                        }
                        }>Se déconnecter</button>
                    </header>
            }
            </connexionContext.Consumer>
        );
    }
}

export default Profilnavbar;