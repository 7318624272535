import React, {useEffect, useState} from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate} from "react-router-dom";
import {connexionContext} from "./context/context";
import {musicContext, defaultMusic} from "./context/musicContext";
import Connexionpage from "./pageconnexion/Connexionpage";
import Createaccpage from "./pagecreation/Createaccpage";
import Encyclopediepage from "./pageencyclopedie/Encyclopediepage";
import Menupage from "./pagemenu/Menupage";
import Patchpage from "./pagepatch/Patchpage";
import Profilpage from "./pageprofil/Profilpage";
import Jouerpage from "./pagejouer/Jouerpage";

import './index.scss';



import {Gameapp as GameApp} from './gameapp/gameapp.js';




const App = (props) => {
    if (!window.localStorage.getItem("volume")){
        window.localStorage.setItem("volume", "100");
    }

    const location = useNavigate();

    const [loading, setLoading] = useState(true);
    const [login, setLogin] = useState(null);

    const [musicsrc, setMusicsrc] = useState(defaultMusic);
    const [music] = useState(new Audio(musicsrc));
    const [volume, setVolume] = useState(window.localStorage.getItem("isMuted") ? 0 : window.localStorage.getItem("volume"));

    const disconnect = (() => {
        fetch("/api/login/disconnect", {method : 'DELETE'}).then(res => {
            setLogin(null);
        })
    });

    let context = {
        login : login,
        setLogin : setLogin,
        disconnect : disconnect
    }

    useEffect(() => {
        music.src = musicsrc;
        music.load();

        let playAttempt = setInterval(() => {
            music.pause();
            music.currentTime = 0;
            music.play().then(() => {
                clearInterval(playAttempt);
                music.addEventListener("ended", () => {
                    music.currentTime = 0;
                    music.play();
                })
            });
        }, 3000);

        return () => {
            music.pause();
        };
    }, [music, musicsrc]);

    useEffect(() => {
        music.volume = volume/100/2;
    }, [volume, music]);

    useEffect(() => {
        fetch("/api/login/").then(res => {
            if (res.status === 200){
                return res.text();
            } else {
                setLogin(null);
                throw new Error("Pas connecté");
            }
        }).then(text => {
            setLogin(text);
            setLoading(false);
        }).catch(err => {
            //console.log(err);
            setLoading(false);
        })
    }, [location])

    return (
        <connexionContext.Provider value={context}>
            <musicContext.Provider value={{musicsrc : musicsrc, setMusicsrc : setMusicsrc}}>
                <div id="App">
                    {loading ?  <h1>Loading</h1> :

                            <Routes>
                                <Route key={1} path="/" exact element={login ? <Profilpage/> : <Menupage/>} />
                                <Route key={2} path="/jouer" exact element={login ? <Jouerpage/> : <Menupage/>} />
                                <Route key={3} path="/patch" exact element={<Patchpage />} />
                                <Route key={4} path="/encyclopedie" exact element={<Encyclopediepage />} />
                                {login ?
                                    [<Route key={6} path={"/jouer/game"} element={<GameApp/>}/>]
                                    :
                                    [<Route key={6} path="/creation-compte" exact element={<Createaccpage/>}/>,
                                        <Route key={7} path="/connexion-compte" exact element={<Connexionpage />} />]
                                }
                                <Route key={5} path={"*"} element={<Navigate to={"/"}/>}/>
                            </Routes>

                    }
                    <div className="parametre-audio">
                        <div className={"audio-volume" + (parseInt(volume) === 0 ? " muted" : (volume <= 50 ? " lv1" : ""))} onClick={() => {
                            if(volume === 0){
                                setVolume(window.localStorage.getItem("volume"));
                                window.localStorage.removeItem("isMuted");
                            }else{
                                setVolume(0);
                                window.localStorage.setItem("isMuted", 1);
                            }
                        }}></div>
                        <input type={"range"} min={0} max={100} name={"volume"} value={volume} onChange={(e) => {
                            setVolume(e.target.value);
                            window.localStorage.setItem("volume", e.target.value);
                            if(parseInt(e.target.value) === 0){
                                window.localStorage.setItem("isMuted", 1);
                            }else{
                                window.localStorage.removeItem("isMuted");
                            }
                        }}/>
                    </div>
                </div>
            </musicContext.Provider>
        </connexionContext.Provider>
    );
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<Router><App /></Router>);
