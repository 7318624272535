import React from 'react';
import {UnitFocusedContext} from './unitfocusedcontext.js';

export class MovementPanel extends React.Component {
    render(){
        return <UnitFocusedContext.Consumer>
            {({unit}) => (
                <div id='movement-panel' className='movement-panel' /* style={this.props.style} */>
                    {unit && [
                        <div className='button  rpgui-container rpgui-cursor-point framed-golden fleche' onClick={() => {unit.move({x : -1})}} key={2}><div>gauche</div></div>,
                        <div className='button  rpgui-container rpgui-cursor-point framed-golden fleche' onClick={() => {unit.move({y : -1})}} style={{transform : 'rotate(90deg)'}} key={3}><div>haut</div></div>,
                        <div className='button  rpgui-container rpgui-cursor-point framed-golden fleche ' onClick={() => {unit.move({y : 1})}} style={{transform : 'rotate(-90deg)'}} key={4}><div>bas</div></div>,
                        <div className='button  rpgui-container rpgui-cursor-point framed-golden fleche' onClick={() => {unit.move({x : 1})}} style={{transform : 'rotate(180deg)'}} key={1}><div>droite</div></div>,
                        (unit.props.unit.name === "Catapulte" ? 
                            [
                            <div className='button rpgui-container rpgui-cursor-point framed-golden rotate' onClick={() => {unit.rotate(-1)}} key={6} style={{transform : 'scaleX(-1)'}}><div>tourner gauche</div></div>,
                            <div className='button rpgui-container rpgui-cursor-point framed-golden rotate' onClick={() => {unit.rotate(1)}} key={5}><div>tourner droite</div></div>
                        ] : "")
                    ]}
                </div>
            )}            
        </UnitFocusedContext.Consumer>
    }
}
