import React from 'react';
import {socket, local} from './gameapp.js';
import {Units, UnitSelectionedContext} from './unitselectorcontext.js';
import {UnitFocusedContext} from './unitfocusedcontext.js';
import {catapultesRange, archerRange, range} from './inRange.js'

class Case extends React.Component{
    constructor(props){
        super(props);

        this.event = this.event.bind(this);
        this.spawn = this.spawn.bind(this);
        this.unspawn = this.unspawn.bind(this);
        
    }

    componentDidMount(){
        socket.on("moved", (arg) => {
            if (this.props.unit.name && arg.x === this.props.unit.coords.x && arg.y === this.props.unit.coords.y  ){
                this.event();
            }
        })
    }

    componentWillUnmount(){
        this.rotate = undefined;
    }

    event(e){
        if (this.props.focus && this.props.unit && this.props.focus.team !== this.props.unit.team){
            this.props.focus.event();
        } else 
        if (this.props.unit != null && this.props.parent.state.case_selected !== this && this.props.unit.team === local.team && this.props.parent.props.lap === local.name){
            let cases = {};
            
            for (let x = 0; x<17; x++){
                for (let y = 0; y<17; y++){
                    if (x !== this.props.unit.coords.x || y !== this.props.unit.coords.y){
                        if (this.props.unit.name === "Catapulte") {
                            if (catapultesRange(this.props.unit.coords, this.props.unit.portee, {x : x, y : y}, this.props.unit.zone_type, this.props.parent.props.map.map[x][y].zone_type)){
                                cases[x + 100*y] = {x : x, y : y, event : () => {this.attack({x : x, y : y})}, team : this.props.unit.team}
                            }
                        } else if (this.props.unit.name === "Archer") {
                            if (archerRange(this.props.unit.coords, this.props.unit.portee, {x : x, y : y},  this.props.unit.zone_type, this.props.parent.props.map.map[x][y].zone_type)){
                                cases[x + 100*y] = {x : x, y : y, event : () => {this.attack({x : x, y : y})}, team : this.props.unit.team}
                            }
                        } else {
                            if (range(this.props.unit.coords, this.props.unit.portee, {x : x, y : y},  this.props.unit.zone_type, this.props.parent.props.map.map[x][y].zone_type)){
                                cases[x + 100*y] = {x : x, y : y, event : () => {this.attack({x : x, y : y})}, team : this.props.unit.team}
                            }
                        }
                    }   
                }
            }


            this.props.parent.setState({
                case_selected : this,
                cases_focus : cases
            })
            this.context.changeFocus(this);
        } else {
            this.props.parent.setState({
                case_selected : null,
                cases_focus : {}
            });
            this.context.changeFocus(null);
        }
        
    }

    move(coordsRelat){
        this.props.parent.setState({
            case_selected : null,
            cases_focus : {}
        });
        this.context.changeFocus(null);
        socket.emit("move", {
            from : this.props.unit.coords,
            to : coordsRelat
        })
    }

    rotate(r){
        this.props.parent.setState({
            case_selected : null,
            cases_focus : {}
        });
        this.context.changeFocus(null);

        if (r > 0) {
            socket.emit("rotate", {
                from : this.props.unit.coords,
                rotate : "right"
            })
        } else if ( r < 0) {
            socket.emit("rotate", {
                from : this.props.unit.coords,
                rotate : "left"
            })
        }
    }

    attack(coords){
        socket.emit("attack", {
            from : this.props.unit.coords,
            to : coords
        })
    }

    spawn(unit){
        socket.emit("spawn", {
            coords : this.props.coords,
            type : Units[unit].name
        })
    }

    unspawn(e){
        e.preventDefault();
        if (this.props.unit.name){
            socket.emit("unspawn", {
                coords : this.props.coords
            })
        }
    }

    render(){
        let style = {
            gridRow : this.props.coords.y+1,
            gridColumn : this.props.coords.x+1
        }
        let unite = this.props.unit.name ? this.props.unit.name + " " + this.props.unit.team : "";
        let focused = this.props.focus ? "focus rpgui-cursor-point" : "";
        let clickable = (this.props.status === "preparation") || (this.props.unit != null && this.props.unit.team === local.team) ? " click rpgui-cursor-point " : "";
        let content = (this.props.unit.name ? [
            <p key={1} className='hp'>{this.props.unit.hp + " HP"}</p>,
            <p key={3} className='pm'>{this.props.unit.pm} PM</p>,
            <div key={2} className='hp-bars' style={{'--size' : ((this.props.unit.hp/this.props.unit.maxHP)*100)+"%"}}/>
            ] : "");
        let rotate = this.props.unit.name === "Catapulte" ? " r" + this.props.unit.coords.r : "";

        return (this.props.status === "preparation" ? <UnitSelectionedContext.Consumer>
            {({unit}) => (
            <div className={"unit " + unite + clickable + rotate} onClick={() => {this.spawn(unit)}} onContextMenu={this.unspawn} style={style}>
                {content}
            </div>)}
        </UnitSelectionedContext.Consumer> : 
        <div className={"unit " + unite + clickable + " " + focused + rotate} onClick={this.event} style={style}>
            {content}
        </div>
        )
            
    }
}
Case.contextType = UnitFocusedContext

export class Grid extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            case_selected : null,
            cases_focus : {}
        };
    }


    render(){
        
        let cases = [];
        this.props.map.map.forEach((element, index1) => {
            element.forEach((elt, index2) => {
                cases.push(<Case key={index1+"/"+index2} parent={this} focus={this.state.cases_focus[index1 + 100*index2]} status={this.props.status} unit={elt} coords={{x : index1, y : index2}}/>)
            })
        });

        return <div className={"gameGrid rpgui-container framed-bois"} id="gameGrid" style={{'--image' : `url("` + this.props.map.image + `")`, ...this.props.style}}>
                {this.props.status === "preparation" && (local.team === "bleu" ? 
                <div className='spawnZone bleu' style={{gridRowStart : 12, gridRowEnd : 18, gridColumnStart : 1, gridColumnEnd : 18}}/> : 
                <div className='spawnZone rouge' style={{gridRowStart : 1, gridRowEnd : 7, gridColumnStart : 1, gridColumnEnd : 18}}/>)}
                {cases}
                {this.props.children}
            </div>
           
    }
}