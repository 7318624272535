import React from "react";
import Navbar from "../elements/Navbar";
import Retourbutton from "../elements/Retourbutton";
import Patch from "./patch/Patch.jsx";

import background from "../images/backgroundparchemin.jpg";
import logoimg from "../images/logo.jpg";

import "./Patchpage.css";

const Patchpage = () => (
  <div>
    <Navbar titre={"Art Of War - Notes de patch"} logoimg={logoimg} />
    <div id="BoitePatch" style={{ backgroundImage:`url(${background})` }}>
        <div id="ScrollPatch">
            <Patch />
        </div>
      <Retourbutton />
    </div>
  </div>
);

export default Patchpage;