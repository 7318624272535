import React from "react";
import {Link} from "react-router-dom";

import "./Retourbutton.css";

const Retourbutton = () => {
    return (
        <Link className="Retourbutton" to={"/"}>Retour</Link>
    );
};

export default Retourbutton;