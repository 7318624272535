import React from 'react';
import {socket} from './gameapp.js'  
import {PlayerDisplayer} from './player';
import {Game} from './game';


export class Salon extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            status : "waiting",
            id : ""
        }
    }

    componentDidMount(){
        socket.emit("requestSalon");
        socket.on('salon', (arg ) => {
            this.setState({
                status : arg.status,
                id : arg.id
            })
        })
    }

    render(){
        return <div className="salon">
            
                <div className="salon-info">   
                    {this.state.status === "waiting" && [<p key={1}>En attente du lancement de la partie</p>]}
                </div>
                
                <PlayerDisplayer/>
                {this.state.status === "running" && <Game/>}
    
        </div>
    }
}