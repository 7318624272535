import {socket} from './gameapp.js'
import {useState} from "react";
export function SurrendButton({children}) {
    const [dialog, setDialog] = useState(false);

    const sendSurrend = () => {
        socket.emit("surrend", "ok");
        setDialog(!dialog);
    }

    const opencloseDialog = () => {
        setDialog(!dialog);
    }

    return (<div>
        {dialog ? <div className={"dialog-surrend"}>
                <p>Êtes vous sur de vouloir abandonner ?</p>
                <button onClick={sendSurrend}>Oui</button>
                <button onClick={opencloseDialog}>Non</button>
            </div> :
            <button style={{fontSize: '60%', paddingLeft: '20px', paddingRight: '20px'}}
                    className="readybutton rpgui-button" onClick={opencloseDialog}>{children}</button>
        }
        </div>)
    }