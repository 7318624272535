import React from "react";

import coin from "../../images/coin.jpg";

import "./Encyclopedie.scss";

const Encyclopedie = () => (
<div>
    <ol>
        <li>
            <h2 id="Sommaire">Sommaire</h2>
            <ul>
                <li><a href="#Apercu">Apercu</a></li>
                <li><a href="#But du jeu">But du jeu</a></li>
                <li><a href="#Phases de jeu">Phases de jeu</a></li>
                <li><a href="#Terrains">Terrains</a></li>
                <li><a href="#Unités">Unités</a></li>
                <li><a href="#Se déplacer">Se déplacer</a></li>
                <li><a href="#Attaquer">Attaquer</a></li>
            </ul>
        </li>
        <li>
            <h2 id="Apercu">Apercu</h2>
            <p>
                Art Of War est un jeu de stratégie en ligne où deux joueurs s’affrontent dans un 1v1 sur un 
                plateau de jeu 17x17 représentant un champ de bataille.
            </p>
        </li>
        <li>
            <h2 id="But du jeu">But du jeu</h2>
            <p>
                Le but du jeu est d'éliminer l'entiereté de l'armée adverse. Pour cela, les joueurs pourront 
                choisir la composition de leur armée ainsi que le placement de celle-ci durant la phase de 
                préparation. Ensuite, phase de combat et ils jouent chacun leur tour jusqu'à la défaite de l’un 
                des deux. 
            </p>
        </li>
        <li>
            <h2 id="Phases de jeu">Phases de jeu</h2>
            <p>
                Il existe deux phases de jeu: 
            </p>
            <ol id="markervh">
                <li>
                    <h3>Phase de préparation :</h3>
                    <p>
                        La phase de préparation est celle durant laquelle les joueurs choisissent les unités 
                        qui composeront leur armée. Pour cela, ils disposent chacun de 1800
                        <img className="piece" src={coin} alt="pièce"></img> 
                        et de 6 types d’unités achetables qu'ils pourront acheter et poser sur le champ de bataille. 
                    </p>
                    <ul>
                        <li>
                            <h4>Déploiement :</h4>
                            <p>
                                Le joueur du haut ne peut déployer ses unités que sur les 6 lignes du haut du champ de 
                                bataille et inversement pour le joueur du bas.
                            </p>
                        </li>
                    </ul>
                    <p>
                        Quand un joueur se prépare, il ne voit pas la préparation de l’autre joueur. Cette phase est 
                        cruciale et déterminera la manière dont se dérouleront les premiers échanges entre les deux 
                        armées.
                    </p>
                </li>
                <li>
                    <h3>Phase de combat :</h3>
                    <p>
                        La phase de combat suit la phase de préparation. Durant cette phase, les joueurs jouent leur 
                        tour l’un après l’autre. Une fois que les deux joueurs ont joué leur tour, le tour suivant 
                        commence. Durant son tour, un joueur peut utiliser toutes ses unités encore sur le champ de 
                        bataille tant qu’elles ont des PA (point d’action).
                    </p>
                    <p>
                        Durant cette phase, chaque unité peut en règle générale effectuer 2 actions :
                    </p>
                    <ul>
                        <li>
                        <h4>Se déplacer :</h4>
                        <p>
                            Une unité peut se déplacer si elle a au moins 1 PA. Il est impossible de se déplacer en 
                            diagonale. Un mouvement de 1 case coûte en général 1 PA mais cela peut changer en fonction 
                            du terrain et de l’unité. 
                        </p>
                        </li>
                        <li>
                        <h4>Attaquer :</h4>
                        <p>
                            Une unité peut attaquer si elle a au moins 0 PA. Elle ne peut attaquer que les unités 
                            étant dans sa portée d’attaque. Attaquer fait automatiquement passer l’unité à -1 PA.
                        </p>
                        </li>
                    </ul>
                </li>
            </ol>
        </li>
        <li>
            <h2 id="Terrains">Terrains</h2>
            <p>Le terrain influe l’issue de différente manière en influant sur les déplacements et attaques des 
            unités. Il existe pour l’instant 5 types de terrain
            </p>
            <ol id="markervh">
                <li>
                    <h3>Plaine :</h3>
                    <p>
                        Terrain de base qui n’affecte rien.
                    </p>
                </li><li>
                    <h3>Colline :</h3>
                    <p>
                        Quand une unité distance est sur une colline, elle gagne 1 de portée sur toutes les cases 
                        de niveau inférieur (tout sauf colline). Pour toutes les unités, monter sur une colline coûte 
                        2 PA au lieu de 1.
                    </p>
                </li><li>
                    <h3>Forêt :</h3>
                    <p>
                        La cavalerie a un coût de déplacement de 2 quand elle arrive sur une case de forêt. Les 
                        unités se situant dans la forêt subissent 2 fois moins de dégâts des unités distance. Les 
                        unités distance font deux fois moins de dégats quand elles se situent dans la forêt.
                    </p>
                </li><li>
                    <h3>Ville :</h3>
                    <p>
                        Les unités se situant dans une ville subissent 2 fois moins de dégâts des catapultes et 4 
                        fois moins des archers. Elles subissent aussi 2 fois moins de dégâts des unités de mêlée 
                        qui ne sont pas dans la ville.
                    </p>
                </li><li>
                    <h3>Eau :</h3>
                    <p>
                        Aucune unité ne peut apparaître ou se déplacer sur un case d'eau.
                    </p>
                </li>
            </ol>
        </li>
        <li>
            <h2 id="Unités">Unités</h2>
            <p>
                Il existe 6 types d’unités qui ont chacune leurs avantages et inconvénients. Le nombre de HP 
                (points de vie) d’une unité correspond au nombre de soldats encore en vie dans l’unité. 
                Certaines unités en contrent d’autres. Le tableau des contres vous indique les multiplicateurs 
                de dégâts entre les unités (voir <a href="#Attaquer">Attaquer</a>).
            </p>
            <ol id="markervh">
                <li>
                <h3>Épéiste :</h3>
                <ul>
                    <p>
                        L’unité d’épéistes est l'unité de base du jeu. C’est la plus polyvalente et résistante.
                    </p>
                    <p>
                        Ses statistiques sont :
                    </p>
                    <ul id="listeunite">
                        <li>180 HP</li>
                        <li>Dégats = (hp + hpmax) / 20</li>
                        <li>2 PA</li>
                        <li>Portée de 1 diagonales comprises</li>
                        <li>Prix : 120<img className="piece" src={coin} alt="pièce"></img></li>
                    </ul>
                </ul>
                </li><li>
                <h3>Archer :</h3>
                <ul>
                    <p>
                        L’unité d’archer est l’unité distance de base. Elle est utilisée pour attaquer de loin 
                        les unités corps à corps, notamment celles de lanciers qui subissent plus de dégâts. 
                        Par contre, l’unité d’archers doit être protégée pour éviter de disparaître suite à 
                        une charge de cavalerie (voir <a href="#Attaquer">Attaquer</a>).
                    </p>
                    <p>
                        Ses statistiques sont :
                    </p>
                    <ul id="listeunite">
                        <li>150 HP</li>
                        <li>Dégats = (hp + hpmax) / 20</li>
                        <li>2 PA</li>
                        <li>Portée de 4 diagonales non comprises</li>
                        <li>Prix : 100<img className="piece" src={coin} alt="pièce"></img></li>
                    </ul>
                </ul>
                </li><li>
                <h3>Cavalier :</h3>
                <ul>
                    <p>
                        L’unité de cavaliers est l’unité la plus mobile du jeu. Elle peut être utilisée pour 
                        flanquer l’armée adverse, assassiner les unités distance sur lesquelles elle à de gros 
                        bonus d’attaque mais elle peut se faire annihiler par l’unité de lanciers 
                        (voir <a href="#Attaquer">Attaquer</a>).
                    </p>
                    <p>
                        Ses statistiques sont :
                    </p>
                    <ul id="listeunite">
                        <li>100 HP</li>
                        <li>Dégats = (hp + hpmax) / 10</li>
                        <li>5 PA</li>
                        <li>Portée de 1 diagonales comprises</li>
                        <li>Prix : 90<img className="piece" src={coin} alt="pièce"></img></li>
                    </ul>
                </ul>
                </li><li>
                <h3>Lancier :</h3>
                <ul>
                    <p>
                        L’unité de lanciers est une unité anti-cavalerie mais elle est très sensible au 
                        harcèlement à distance (voir <a href="#Attaquer">Attaquer</a>).
                    </p>
                    <p>
                        Ses statistiques sont :
                    </p>
                    <ul id="listeunite">
                        <li>140 HP</li>
                        <li>Dégats = (hp + hpmax) / 20</li>
                        <li>3 PA</li>
                        <li>Portée de 1 diagonales comprises</li>
                        <li>Prix : 90<img className="piece" src={coin} alt="pièce"></img></li>
                    </ul>
                </ul>
                </li><li>
                <h3>Catapulte :</h3>
                <ul>
                    <p>
                        L’unité de catapultes est l’unité avec le plus de portée du jeu et possède un système de 
                        rotation unique puisqu’elle ne peut tirer que dans une direction à la fois. 
                    </p>
                    <p>
                        Elle possède 8 directions possibles :
                    </p>
                    <ul id="listeunite">
                        <li>Nord</li>
                        <li>Nord-Est</li>
                        <li>Est</li>
                        <li>Sud-Est</li>
                        <li>Sud</li>
                        <li>Sud-Ouest</li>
                        <li>Ouest</li>
                        <li>Nord-Ouest</li>
                    </ul>
                    <p>
                        L’unité est donc la seule à avoir une mécanique de rotation. Durant son tour, elle ne 
                        peut faire qu’une action. Se déplacer, attaquer ou effectuer une rotation.
                    </p>
                    <p>
                        Ses statistiques sont :
                    </p>
                    <ul id="listeunite">
                        <li>60 HP</li>
                        <li>Dégats = (hp + hpmax) / 4</li>
                        <li>1 PA</li>
                        <li>Portée de 7 avec le système de direction.</li>
                        <li>Prix : 150<img className="piece" src={coin} alt="pièce"></img></li>
                    </ul>
                </ul>
                </li><li>
                <h3>Général :</h3>
                <ul>
                    <p>
                        L’unité du général est la plus importante et puissante du jeu. Chaque joueur ne peut en 
                        posséder qu'une seule et elle n’a pas de coût. Elle contre toutes les autres unités du 
                        jeu et offre un bonus de dégâts aux unités autour d’elle. Mais sa mort réduit grandement 
                        les dégâts de toutes vos unités (voir <a href="#Attaquer">Attaquer</a>).
                    </p>
                    <p>
                        Ses statistiques sont :
                    </p>
                    <ul id="listeunite">
                        <li>160 HP</li>
                        <li>Dégats = (hp + hpmax) / 20</li>
                        <li>2 PA</li>
                        <li>Portée de 1 diagonales comprises</li>
                        <li>Prix : Aucun</li>
                    </ul>
                </ul>
                </li>
            </ol>
        </li>
        <li>
            <h2 id="Se déplacer">Se déplacer</h2>
            <p>
                Tant qu'une unité a au moins 1 PA, elle peut se déplacer ce qui lui fait perdre 1 PA en 
                général. Cependant le terrain influe sur les coûts de déplacement des unités.
                <ul>
                    <li>Le coût de déplacement des cavaliers est de 2 dans la forêt.</li>
                    <li>Monter sur une colline coûte 2 PA.</li>
                    <li>Quand une catapulte se déplace, ses PA passent à -1.</li>
                    <li>Il est impossible de se déplacer sur une case d'eau (lacs/rivières).</li>
                </ul>
                <p>
                    Pour plus de précision, voir <a href="#Terrains">Terrains</a>.
                </p>
            </p>
        </li>
        <li>
            <h2 id="Attaquer">Attaquer</h2>
            <p>
                Tant qu'une unité a au moins 0 PA, elle peut attaquer ce qui la fait passer à -1 PA.
            </p>
            <p>
                Quand une unité en attaque une autre, plein de facteurs rentrent en compte pour calculer les 
                dégâts infligés. Tout d’abord, le terrain rentre en compte (voir <a href="#Terrains">Terrains</a>). 
                De plus, chaque type unité a des multiplicateurs sur les autres types (voir tableau ci-dessous). 
            </p>
            <table>
                <tr>
                    <td colspan="2" rowspan="2"></td>
                    <td class="titre" colspan="6">DEF</td>
                </tr>
                <tr>
                    <td class="titre">Épéiste</td>
                    <td class="titre">Archer</td>
                    <td class="titre">Cavalier</td>
                    <td class="titre">Lancier</td>
                    <td class="titre">Catapulte</td>
                    <td class="titre">Général</td>
                </tr>
                <tr>
                    <td  class="titre" rowspan="6">ATK</td>
                    <td class="titre">Épéiste</td>
                    <td id="td1">1</td>
                    <td id="td2">2</td>
                    <td id="td1">1</td>
                    <td id="td1">1</td>
                    <td id="td2">2</td>
                    <td id="td1">1</td>
                </tr>
                <tr>
                    <td  class="titre">Archer</td>
                    <td id="td1">1</td>
                    <td id="td1">1</td>
                    <td id="td1">1</td>
                    <td id="td2">2</td>
                    <td id="td1">1</td>
                    <td id="td1">1</td>
                </tr>
                <tr>
                    <td  class="titre">Cavalier</td>
                    <td id="td1">1</td>
                    <td id="td4">4</td>
                    <td id="td1">1</td>
                    <td id="td05">0.5</td>
                    <td id="td4">4</td>
                    <td id="td1">1</td>
                </tr>
                <tr>
                    <td  class="titre">Lancier</td>
                    <td id="td1">1</td>
                    <td id="td2">2</td>
                    <td id="td2">2</td>
                    <td id="td1">1</td>
                    <td id="td2">2</td>
                    <td id="td1">1</td>
                </tr>
                <tr>
                    <td  class="titre">Catapulte</td>
                    <td id="td1">1</td>
                    <td id="td1">1</td>
                    <td id="td05">0.5</td>
                    <td id="td2">2</td>
                    <td id="td1">1</td>
                    <td id="td1">1</td>
                </tr>
                <tr>
                    <td  class="titre">Général</td>
                    <td id="td3">3</td>
                    <td id="td3">3</td>
                    <td id="td3">3</td>
                    <td id="td3">3</td>
                    <td id="td3">3</td>
                    <td id="td1">1</td>
                </tr>
            </table>
            <p>
                Les dégâts de l’attaquant dépendent de ses HP actuelles, par exemple, une unité d’épéistes avec 
                100% de ses HP fera 100% de ses dégâts (DMG) alors qu’une unité d’épéistes avec 50% de ses HP fera 
                75% DMG. Si une unité se situe dans les 8 cases autour de son général, elle fera +25% de dégâts 
                mais si son général meurt ses dégâts seront divisés par deux.
            </p>
        </li>
    </ol>
</div>);

export default Encyclopedie;