import React from 'react';

export class Form extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            game_id : ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.send = this.send.bind(this);
    }

    send(){
        this.props.callback(this.state)
    }

    handleChange(event){
        let state = {}
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    render(){
        return <div className="connect-form" id="connect-form">
            <input name="game_id" type="text" value={this.state.game_id} placeholder="Identifiant de partie" onChange={this.handleChange} />
            <button onClick={this.send}>Se connecter</button>
        </div>
    }
}
