import React from "react";
import Navbar from "../elements/Navbar";
import Retourmenubutton from "../elements/Retourmenubutton";

import background from "../images/The_public_viewing_davids_coronation_at_the_louvre.jpg";
import logoimg from "../images/logo.jpg";

import "./Createaccpage.css";
import {connexionContext} from "../context/context";

export default class Createpage extends React.Component {

  static contextType = connexionContext;

  constructor(props){
    super(props);
    this.state = {
      id: "", 
      mdp: "",
      error : null
    };
  }
  

  handleChange(event){
    event.preventDefault();
    switch (event.currentTarget.id) {
      case "id":
        this.setState({id: event.currentTarget.value});
        break;
      case "pswd" :
        this.setState({mdp: event.currentTarget.value});
        break;
      default:
        break;
    }
  };

  handleSubmit(event){
    if(this.state.id === "" || this.state.pswd === ""){
      console.log("Les champs sont vides");
    } else {
      fetch("/api/login/new",{method : "POST",headers : { "Content-Type" : "application/json"}, body : JSON.stringify({login : this.state.id, pswd : this.state.mdp})}).then((res) => {
        console.log(res.status)
        if(res.status === 200){
          this.context.setLogin(this.state.id);
        } else if (res.status === 403) {
          this.setState({
            error : "Un compte existe déjà avec cet identifiant"
          });
        } else {
          this.setState({
            error : "Problème de connexion"
          });
        }
      });
    }
  }

  render(){
    return (
      <div>
      <Navbar titre={"Art Of War - Création de compte"} logoimg={logoimg} />
        <div id="BoiteCreateAcc" style={{ backgroundImage:`url(${background})` }}>
          <input id="id" type="text" placeholder="Identifiant" value={this.state.id} onChange={this.handleChange.bind(this)}/>
          <input id="pswd" type="password" placeholder="Mot de passe" value={this.state.mdp} onChange={this.handleChange.bind(this)} />
          <p>{this.state.error}</p>
          <a href="/connexion-compte">J'ai déjà un compte</a>
          <button id="Creer" onClick={this.handleSubmit.bind(this)}>Créer un compte</button>
          <Retourmenubutton />
        </div>
      </div>
    )
  }
}
