import React from "react";
import Navbar from "../elements/Navbar";
import Retourbutton from "../elements/Retourbutton";
import Encyclopedie from "./encyclopedie/Encyclopedie.jsx";

import background from "../images/backgroundparchemin.jpg";
import logoimg from "../images/logo.ico";

import "./Encyclopediepage.css";

const Encyclopediepage = () => (
  <div className={"encyclo"}>
    <Navbar titre={"Art Of War - Encyclopédie"} logoimg={logoimg} />
    <div id="BoiteEncyclopedie" style={{ backgroundImage:`url(${background})` }}>
      <div id="ScrollEncyclopedie">
        <Encyclopedie />
      </div>
      <Retourbutton />
    </div>
  </div>
);

export default Encyclopediepage;
