import React from "react";

import map_riviere from "./map_rivière.png";
import map_lac from "./map_lac.png";

import "./Patch.css";

const Patch = () => (
    <div id="Patchs">
        <div>
            <h2>Note du patch 0.3.2 :</h2>
        </div>
        <h3>- Ajustement :</h3>
        <h4>- Général :</h4>
        <p>
            <span id="Barré">Quand le général est mort, les unités infligent 50% de leurs dégats jusqu'à la fin de la partie.
            </span> &rarr; Quand le général meurt, les unités infligent 50% de leurs dégats jusqu'à la fin de la partie <span id="Vert">
            ou la mort du général adverse.</span>
        </p>
        <p>
            "Cela permettra aux fins de parties avec les deux généraux morts d'être plus dynamiques puisque les malus des deux camps
            s'annuleront."
        </p>
        <h3>- Réglages mineurs et correction de bugs :</h3>
        <p>
            Correction du bug du bouton retour de l'encyclopédie. Ajout d'un bouton pour pouvoir annuler la recherche de partie.
            Refonte graphique mineure des liens de l'encyclopédie et des statistiques en partie.
        </p>

        <div>
            <h2>Note du patch 0.3.1 :</h2>
        </div>
        <h3>- Ajout :</h3>
        <h4>- Musique :</h4>
        <p>
            Ajout de trois musique dont une pour le menu et deux pour les parties.
            
        </p>
        <h3>- Amélioration :</h3>
        <p>
            Il y a eu des améliorations au niveau des graphismes durant les parties. Notamment autour du plateau,
            sur les cotés pour présenter les joueurs et en bas pour sélectionner les unités. La pièce de la 
            monnaie du jeu a aussi été ajoutée.
        </p>

        <div>
            <h2>Note du patch 0.2.1 :</h2>
        </div>
        <h3>- Ajout :</h3>
        <h4>- Carte :</h4>
        <p>
            <h5>- Rivière :</h5>
            Une carte assez fermée sur laquelle il faudra jouer d'une manière positionnelle et mesurer ses offensives
            avant de passer à l'action. La ville est la position clé de la carte offert au joueur rouge mais il peut être 
            contesté par le joueur bleu en début de partie.<br/>
            <img className="Image" src={map_riviere} alt="map_riviere"></img><br/>
            <h5>- Lac :</h5>
            Une carte avec deux théatres d'opérations, celui de gauche est un petit défilé qui peut être facilement
            défendu tandis que celui de droite est le champ de bataille principal. Trouver comment contrôler les
            zones et contourner son adversaire sera la clé sur cette carte.<br/>
            <img className="Image" src={map_lac} alt="map_lac"></img><br/>
        </p>

        <div>
            <h2>Note du patch 0.1.1 :</h2>
        </div>
        <h3>- Up :</h3>
        <h4>- Catapultes :</h4>
        <p>
            - <span id="Barré">40 HP</span> &rarr; <span id="Vert">60 HP</span> <br/>
            - Diviseur <span id="Jaune">2</span> <br/>
            - <span id="Barré">20 DMG</span> &rarr; <span id="Vert">30DMG</span> <br/>
            "Les catapultes étaient trop fragiles et pouvaient même se faire tuer en un seul coup par des unités qui 
            étaient grandement affaiblies. De plus, leur gameplay très exigeant n'était pas récompensé, ce up 
            d'HP augmente donc aussi leurs dégâts afin d'avoir un ratio risque/récompense plus intéressant puisque 
            leur diviseur de dégâts ne change pas"
        </p>
        <h3>- Nerf :</h3>
        <h4>- Épéistes :</h4>
        <p>
            - <span id="Barré">200 HP</span> &rarr; <span id="Rouge">180 HP</span> <br/>
            - Diviseur <span id="Jaune">10</span> <br/>
            - <span id="Barré">20 DMG</span> &rarr; <span id="Rouge">18 DMG</span> <br/>
            "Les épéistes étaient trop résistants ce qui les rendait invincibles quand ils se regroupaient, ce 
            nerf permettra aux autres unités de les tomber un peu plus vite. Leur diviseur de dégâts ne 
            change pas ce qui réduit légèrement leurs dégâts"
        </p>
        <br/>
    </div>
);

export default Patch;