import React from 'react';
import { Link } from "react-router-dom";
import {socket, local} from './gameapp.js';
import {UnitSelector} from './unitselector.js';
import {UnitSelectionedContext} from './unitselectorcontext.js';
import {UnitFocusedContext} from './unitfocusedcontext.js';
import {Grid} from './grid';
import {ErrMessage} from './errmessage.js';
import {MovementPanel} from './movementpanel.js';

export class Game extends React.Component{
    constructor(props){
        super(props);

        this.keyPressHandler = this.keyPressHandler.bind(this);

        this.changeUnit = (unitIndex) => {
            this.setState({
                unitSelectioned : {
                    unit : unitIndex,
                    changeUnit : this.state.unitSelectioned.changeUnit
                }
            })
        }

        this.changeFocus = (unit) => {
            this.setState({
                unitFocused : {
                    unit : unit,
                    changeFocus : this.state.unitFocused.changeFocus
                }
            })
        }

        this.state = {
            image_url : "",
            unitSelectioned : {
                unit : 0,
                changeUnit : this.changeUnit
            },
            unitFocused : {
                unit : null,
                changeFocus : this.changeFocus
            }
        };
    }

    componentDidMount(){
        socket.emit("requestGame");
        socket.on('game', (arg) => {
            this.setState({
                ...arg
            })
        }) 

        window.onresize = (event) => {
            let size = this.calcSize();
            document.getElementById('game').style.height = size + "px";
            document.getElementById('game').style.width = size*1.2 + "px";
            document.getElementById('square').style.fontSize = (size/17/4) + "px";
            /* document.getElementById('movement-panel').style.width = size + "px"  */
        }
    }

    componentWillUnmount(){
        window.onresize = () => {}
    }

    calcSize(){
        return Math.round(window.innerHeight*0.96 > (window.innerWidth*0.98*0.65)/1.2 ? (window.innerWidth*0.98*0.65)/1.2 : window.innerHeight*0.96);
    }

    keyPressHandler(event){
        if (this.state.unitFocused.unit){   
            switch (event.key){
                case "z" :
                    this.state.unitFocused.unit.move({y : -1});
                    break;
                case "s" :
                    this.state.unitFocused.unit.move({y : 1});
                    break;
                case "q" :
                    this.state.unitFocused.unit.move({x : -1});
                    break;
                case "d" :
                    this.state.unitFocused.unit.move({x : 1});
                    break;
                case "a" :
                    this.state.unitFocused.unit.rotate(-1);
                    break;
                case "e" :
                    this.state.unitFocused.unit.rotate(1);
                    break;
                default :
                    break;
            }
        }
    }

    render(){
        let size = this.calcSize();
        let font_size = (size/17/4) + "px";
        let stylee = {height : size, width : size*1.2};

        return <div className='game rpgui-container framed-bois ' id="game" style={stylee} onKeyPress={this.keyPressHandler} tabIndex="0">
            <UnitSelectionedContext.Provider value={this.state.unitSelectioned}>
                <UnitFocusedContext.Provider value={this.state.unitFocused}>
                    <MovementPanel />
                    {this.state.map &&
                    <div className='square' id='square' style={{fontSize : font_size}}>
                        <Grid map={this.state.map} lap={this.state.lap} status={this.state.status}>
                            <ErrMessage/>
                        </Grid>
                    </div>} 
                    {this.state.status === "preparation" && <UnitSelector/>}
                    {this.state.status === "fini" && <div className={"winner-displayer"}>
                        {this.state.winner && this.state.winner.name == local.name ? <p>Victoire</p> : <p>Défaite</p> }
                        <Link to={"/"}>Continuez</Link>
                    </div>}
                    
                </UnitFocusedContext.Provider>
            </UnitSelectionedContext.Provider>
            
        </div>
    }
}