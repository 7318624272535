import React from 'react';

export var Units = ["Epeiste", "Archer", "Cavalier", "Lancier", "Catapulte", "General"];
export function setUnits(nouveau){
    Units = nouveau
}
export const UnitSelectionedContext = React.createContext({
    unit : 0,
    changeUnit : () => {}
});
UnitSelectionedContext.displayName = "UnitSelector"