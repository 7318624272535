import React, {useState, useEffect, useRef} from 'react';
import {socket, local} from './gameapp.js';
import { ReadyButton } from './readybutton.js';
import { SurrendButton } from './surrendbutton';
import coin from "../images/coin.jpg";

const Timer = (props) => {
    const Ref = useRef(null);
    const [count, setCount] = useState(props.count);

    const clearTimer = () => {
        if (Ref.current) {
            clearTimeout(Ref.current);
        }
    }

    useEffect(() => {
        setCount(props.count);
        return clearTimer;
    }, [props])

    useEffect(() => {
        clearTimer();
        Ref.current = setTimeout(() => {setCount(count - 1)}, 1000);
    })

    return (<div className={"timer"}>
        {count}
    </div>);
}

class Player extends React.Component{
    render(){
        let infos = []
        if (this.props.infos){
            if (!this.props.infos.connected){
                infos.push(<p key={85}>Déconnecté</p>)
            }
            infos.push(<h3 key={1}>{this.props.infos.name}</h3>);
            infos.push(<p key={2}>{this.props.infos.money}<img className="piece" src={coin} alt="pièce"></img></p>);


            if (this.props.infos.name === local.name && (this.props.show || this.props.lap === local.name)){
                if (this.props.gameStatus === "en cours"){
                    infos = infos.concat([<ReadyButton key={4}>Passer son tour</ReadyButton>])
                } else {
                    infos = infos.concat([<ReadyButton key={4}>Prêt</ReadyButton>])
                }


            }

            if (this.props.infos.name === this.props.lap && this.props.gameStatus === "en cours"){
                infos.push(<Timer key={581} count={this.props.count}/>)
            } else if (this.props.gameStatus === "preparation" && this.props.infos.name === local.name){
                infos.push(<Timer key={581} count={this.props.count}/>);
            }

            if (this.props.show){
                infos.push(<p key={3}>{this.props.infos.ready ? "Prêt" : "Pas prêt"}</p>);
            } else {
                if (this.props.infos.stats){
                    let liste = []
                    for (const [key, value] of Object.entries(this.props.infos.stats)) {
                        liste.push(<tr key={key}>
                            <th>{key}</th>
                            <td>{value.number}</td>
                            <td>{value.hp}</td>
                        </tr>)
                    }
                    let sumhp = Object.values(this.props.infos.stats).map(x => x.hp).reduce((previousValue, currentValue) => previousValue + currentValue);
                    let sumnumber = Object.values(this.props.infos.stats).map(x => x.number).reduce((previousValue, currentValue) => previousValue + currentValue);
                    liste.push(<tr key="total">
                        <td>Total</td>
                        <td>{sumnumber}</td>
                        <td>{sumhp}</td>
                    </tr>)
                    infos.push(<div className='stats' key={10}>
                        <h4>Statistiques</h4>
                        <table>
                            <thead>
                                <tr>
                                    <td></td>
                                    <th>Nombre</th>
                                    <th>HP</th>
                                </tr>
                            </thead>
                            <tbody>
                                {liste}
                            </tbody>
                        </table>
                    </div>)

                    if (this.props.infos.name === local.name){
                        infos.push(<SurrendButton>Abandonner</SurrendButton>)
                    }
                }
                
            }
        } else {
            infos = <h3>Pas de joueurs</h3>;
        }
        return <span className={"player rpgui-container framed-bois " + (this.props.infos ? this.props.infos.team + (this.props.infos.name === local.name ? " you" : " other-player") : "")}>
                {infos}
        </span>
    }
}

export class PlayerDisplayer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            un : {  name : "",
                    team : "",
                    ready : false},
            deux : {name : "",
                    team : "",
                    ready : false},
            show_ready : true,
            lap : null,
            count : null
        }
    }
    componentDidMount(){
        socket.emit("requestPlayer");
        socket.on('player_info', (arg) => {
            this.setState({
                un : arg[0],
                deux : arg[1]
            })
        })

        socket.emit("requestGame");
        socket.on('game', (arg) => {
            this.setState({
                gameStatus : arg.status,
                show_ready : arg.status !== "en cours",
                lap : arg.lap,
                count : arg.count
            })
        })
    }

    render(){
        return <div className="player-display">
            <Player key={1} infos={this.state.un} gameStatus={this.state.gameStatus} show={this.state.show_ready} lap={this.state.lap} count={this.state.count}/>
            <Player key={2} infos={this.state.deux} gameStatus={this.state.gameStatus} show={this.state.show_ready} lap={this.state.lap} count={this.state.count}/>
        </div>
    }
}