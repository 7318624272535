 function catapultesRange(coordsCur, portee, coords, mapTypeCur, mapType){
    let coordsRelat = {x : coordsCur.x - coords.x, y : coordsCur.y - coords.y};
    if(mapTypeCur.includes('c')){
        portee++;
    }
    if(mapTypeCur.includes('f')){
        portee--;
    }
    if(mapType.includes('c')){
        portee--;
    }
    if(coordsCur.r % 2 === 1){
        if (
            (coordsCur.r === 7 && coordsRelat.x <= 0 && coordsRelat.y <= 0) ||
            (coordsCur.r === 1 && coordsRelat.x >= 0 && coordsRelat.y <= 0) ||
            (coordsCur.r === 3 && coordsRelat.x >= 0 && coordsRelat.y >= 0) ||
            (coordsCur.r === 5 && coordsRelat.x <= 0 && coordsRelat.y >= 0)
        ){
            coordsRelat = {x : Math.abs(coordsRelat.x), y : Math.abs(coordsRelat.y)};
            let find = false;
            for (let i = 2; i<=portee && !find; i++){
                if (
                    (coordsRelat.x === i && coordsRelat.y === i) ||
                    (coordsRelat.x === i-1 && coordsRelat.y === i) ||
                    (coordsRelat.x === i && coordsRelat.y === i-1)
                ){
                    find = true;
                }
            }
            return find;
        } else {
            return false;
        }
    } else {
        if(
            (coordsCur.r === 0 && coordsRelat.y <= -2) ||
            (coordsCur.r === 6 && coordsRelat.x <= -2) ||
            (coordsCur.r === 4 && coordsRelat.y >= 2) ||
            (coordsCur.r === 2 && coordsRelat.x >= 2)
        ){
            coordsRelat = {x : Math.abs(coordsRelat.x), y : Math.abs(coordsRelat.y)};
            let find = false;
            for(let i = 2; i<=portee && !find; i++){
                if(
                    (coordsRelat.x === i && coordsRelat.y === 0) ||
                    (coordsRelat.x === i && coordsRelat.y === 1) ||
                    (coordsRelat.x === 0 && coordsRelat.y === i) ||
                    (coordsRelat.x === 1 && coordsRelat.y === i) 
                ){
                    find = true;
                }
            }
            return find;
        } else {
            return false;
        }
    }
}

 function archerRange(coordsCur, portee, coords, mapTypeCur, mapType){
    if(mapTypeCur.includes('c')){
        portee++;
    }
    if(mapTypeCur.includes('f')){
        portee--;
    }
    if(mapType.includes('c')){
        portee--;
    }
    /* console.log(portee, coords); */
    return ((Math.abs(coords.x-coordsCur.x) + Math.abs(coords.y-coordsCur.y)) <= portee);
}

 function range(coordsCur, portee, coords, mapTypeCur, mapType){
    return (Math.abs(coords.x-coordsCur.x) <= portee && Math.abs(coords.y-coordsCur.y) <= portee);
   
}

module.exports = {catapultesRange, archerRange, range}