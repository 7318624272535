import React from "react";
import Profilnavbar from "../elements/Profilnavbar";
import {BoutonMenu} from "../elements/button";
import "./Profilpage.css";

import background from "../images/The_highland_family.jpg";

const Profilpage = () => (
	<div>
		<Profilnavbar />
		<div id="BoiteButton" style={{ backgroundImage:`url(${background})` }}>
			<div id="BoiteButtonGauche" className={"side"}>
				<BoutonMenu to={"/jouer"}>Jouer</BoutonMenu>
				<BoutonMenu disabled to={"/"}>Classement</BoutonMenu>
				<BoutonMenu disabled to={"/"}>Historique</BoutonMenu>
			</div>
			<div id="BoiteButtonDroite" className={"side"}>
				<BoutonMenu disabled>Options</BoutonMenu>
				<BoutonMenu to={"/encyclopedie"}>Encyclopédie</BoutonMenu>
				<BoutonMenu to={"/patch"}>Notes de patch</BoutonMenu>
			</div>
		</div>
	</div>
);

export default Profilpage;