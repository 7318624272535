import React from "react";

import discordimg from "../images/discord.png";
import "./Navbar.css";

const Navbar = ({ titre, logoimg }) => (
  <header className="Header">
    <img className="Logo" src={logoimg} alt="logo"></img>
    <h1 className="Titre">{titre}</h1>
    <div id="Discord">
      <a className="DBox" href="https://discord.gg/MnpmUDbQsS">
        <img className="Discord" src={discordimg} alt="discord"></img>
      </a>
    </div>
    
  </header>
);

export default Navbar;