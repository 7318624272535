import React from "react";
import { Link } from "react-router-dom";

import "./Retourmenubutton.css";

const Retourmenubutton = () => {
    return (
        <Link to="/">
            <button className="Retourmenubutton">Retour au menu</button>
        </Link>
    );
};

export default Retourmenubutton;